import { Image } from '../../types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Virtual } from 'swiper';
import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Container } from '@mui/material';
import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

const styles = {
    SwiperSlide: {
        maxHeight: "512px",
        height: "512px"
    }
}
const SwipeGallery = () => {
    const images: Image[] = useOutletContext<Image[]>();
    const slides = useMemo(() => images.map((image, index) => (
        <SwiperSlide
            key={`slide${image.id}`}
            virtualIndex={index}
        >
            <img
                key={`image${image.id}`}
                className='gallery-photo'
                src={`${image.src}`}
                alt={image.description}
                height={512}
            />
        </SwiperSlide>
    )), [ images ]);

    return (
        <Container maxWidth='lg' >
            <Swiper
                style={styles.SwiperSlide}
                speed={1200}
                modules={[ Navigation, Autoplay, Virtual ]}
                navigation
                virtual={{ addSlidesAfter: 5, cache: true, }}
                loop
                autoplay={{ delay: 15000 }}>
                {slides}
            </Swiper>
        </Container>
    );
};

export default SwipeGallery;
