import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cube';
import { Stack } from '@mui/material';
const Header = () => {
    return (

        <div className='App-header'>
            {/* <div className='flex-row'> */}
            <Stack direction="row" flexWrap="nowrap">
                <span className='header-static'>Plural </span>
                <Swiper
                    direction='vertical'
                    modules={[ Autoplay ]}
                    autoplay={{ delay: 1500 }}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    height={60}
                    roundLengths={true}
                    resizeObserver={true}
                    loop={true}
                    autoHeight={true}
                    allowTouchMove={false}
                    maxBackfaceHiddenSlides={7}
                    preventClicks={true}
                    loopPreventsSliding={true}>
                    <SwiperSlide className='header-text center-text underline'>
                        our community
                    </SwiperSlide>
                    <SwiperSlide className='header-text center-text underline'>
                        our home
                    </SwiperSlide>
                    <SwiperSlide className='header-text center-text underline'>
                        our business
                    </SwiperSlide>
                    <SwiperSlide className='header-text center-text underline'>
                        our park
                    </SwiperSlide>
                    <SwiperSlide className='header-text center-text underline'>
                        our church
                    </SwiperSlide>
                    <SwiperSlide className='header-text center-text underline'>
                        our school
                    </SwiperSlide>
                </Swiper>
                <span className='header-static'> Mural Co.</span>
            </Stack>
        </div >
    );
};

export default Header;
