import {ErrorMessage, FieldProps} from 'formik';
import {InputAdornment, InputLabel, TextField as TextFieldMUI, Typography} from '@mui/material';

interface TextProps extends FieldProps {
    label: string;
    placeholder: string;
}
export const TextField = ({ field, label, placeholder}: TextProps) => {
    return (
        <div style={{marginBottom: '1em'}}>
            <TextFieldMUI sx={{ width: '100%' }} id={label} label={label} placeholder={placeholder} {...field} 
        />
            <Typography variant='subtitle2' style={{color: 'red'}}>
                <ErrorMessage name={field.name} />
            </Typography>
        </div>
    );
};

export const MeasurementField = ({ field, label, placeholder}: TextProps) => {
    return (
        <div style={{marginBottom: '1em'}}>
            <TextFieldMUI id={label} label={label} placeholder={placeholder} {...field} InputProps={{
                "endAdornment": <InputAdornment position="end">inches</InputAdornment>
            }}
        />
            <Typography variant='subtitle2' style={{color: 'red'}}>
                <ErrorMessage name={field.name} />
            </Typography>
        </div>
    );
};
export const LongTextField = ({field, label, placeholder}: TextProps) => {
    return (
        <div style={{marginBottom: '1em'}}>
            <InputLabel>{label}</InputLabel>
            <TextFieldMUI
                fullWidth={true}
                id={label}
                label={label}
                placeholder={placeholder}
                minRows={3}
                maxRows={6}
                size='medium'
                multiline={true}
                {...field}
            />
            <Typography variant='subtitle2' style={{color: 'red'}}>
                <ErrorMessage name={field.name} />
            </Typography>
        </div>
    );
};
