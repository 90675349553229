import { Stack, Typography } from "@mui/material";

const Footer = () => {
    return (
        <div className='footer'>
            <Stack direction="column" >
                <div id="footer-logo"><img src="https://firebasestorage.googleapis.com/v0/b/pluralmurals-6ac41.appspot.com/o/img%2Fassets%2FlogoB.svg?alt=media" alt="plural murals logo small" width={"100%"} /></div>
                <Typography variant='caption'>Phoenix, Arizona</Typography>
                <Typography variant='caption'>Copyright © 2023 Plural Murals. All Rights Reserved.</Typography>
            </Stack>
        </div>
    )
}

export default Footer