import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
interface ConfirmationModalProps {
    name: string;
    isOpen: boolean;
}

const ConfirmationModal = ({ name, isOpen, ...props }: ConfirmationModalProps) => {
const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();

    const handleCloseModal = ():void => {
        navigate("/");
    }
  return (
      <Dialog fullScreen={fullScreen} open={isOpen} className="confirm-page" {...props}>
          <DialogTitle>Success! We recieved your inquiry, {name}.</DialogTitle>
          <DialogContent>We appreciate your interest in fine murals. We will touch-base with you once our designer has a chance to review your design questions.
          Thanks!
          </DialogContent>
          <DialogActions>
              <Button variant="contained" onClick={handleCloseModal}>Home</Button>
          </DialogActions>
      </Dialog>
      
  )
}

export default ConfirmationModal