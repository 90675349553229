import { Inquiry, Image } from './../types';
import axios from "axios";

const baseUrl = 'https://pluralmurals.com/api';
// const baseUrl = 'http://localhost:8008/api';

export const submitInquiry = async (inquiry: Inquiry) => {
    const response = await axios.post<Inquiry>(`${baseUrl}/submit`, inquiry);
    return response;
}

export const getGallery = async () => {
    const response = await axios.get<Image[]>(`${baseUrl}/getGallery`);
    return response.data;
}