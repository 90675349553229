import {Button} from '@mui/material';
import SwipeGallery from '../components/Gallery/SwipeGallery';
import {useNavigate} from 'react-router-dom';

const DesignsPage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <h1 className='mainTagLineA'>Designs</h1>
            <SwipeGallery />
            <section id='inquire-btn-bottom'>
                <div className='center est-btn'>
                    <Button
                        style={{
                            paddingLeft: '8rem',
                            paddingRight: '8rem',
                            paddingBottom: '1rem',
                            paddingTop: '1rem'
                        }}
                        className='est-btn'
                        aria-label='get-estimate-button'
                        variant='contained'
                        size='large'
                        color='error'
                        onClick={() => navigate('/estimate')}>
                        Get a free mural design estimate
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default DesignsPage;
