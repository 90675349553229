import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import ImagesearchRollerIcon from '@mui/icons-material/ImagesearchRoller';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import BrushIcon from '@mui/icons-material/Brush';
const ServiceList = () => {
    return (
        <div className='featureColumn'>
            <div className='featureImageContainer'>
                <img
                    className='featureImage'
                    src='https://firebasestorage.googleapis.com/v0/b/pluralmurals-6ac41.appspot.com/o/img%2Ffeatures%2Finterior-mural-in-progress.webp?alt=media'
                    alt='An interior mural in progress'
                    height={512}
                    width={512}
                />
            </div>
            <div className='featureTextContainer'>
                <h3 className='mainTagLineA'>
                    Exterior and interior options available
                </h3>
                <List id='paintTypesList' className='pad-bot'>
                    <ListItem>
                        <ListItemIcon>
                            <WallpaperIcon fontSize='large' color='primary' />
                        </ListItemIcon>
                        <ListItemText>Wallpaper / Adhesive Wrap</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ImagesearchRollerIcon
                                fontSize='large'
                                color='primary'
                            />
                        </ListItemIcon>
                        <ListItemText>Rolled Paint</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <BrushIcon fontSize='large' color='primary' />
                        </ListItemIcon>
                        <ListItemText>Detail Work</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DesignServicesIcon
                                fontSize='large'
                                color='primary'
                            />
                        </ListItemIcon>
                        <ListItemText>Stencil Design and Printing</ListItemText>
                    </ListItem>
                </List>
            </div>
            <div className='featureImageContainer snap-center'>
                <img
                    className='featureImage'
                    width='412'
                    height='440'
                    src='https://firebasestorage.googleapis.com/v0/b/pluralmurals-6ac41.appspot.com/o/img%2Ffeatures%2Fstreet-art-mural-design-moon-eclipsing-the-sun-cropped.webp?alt=media'
                    alt='mural of wormhole'
                />
            </div>
        </div>
    );
};

export default ServiceList;
