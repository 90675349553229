import {Outlet, useLoaderData} from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import {useEffect, useState} from 'react';
import {Image} from './types';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { initImg } from './initImg';

function App() {
    const [images, setImages] = useState<Image[]>([]);
    const gallery = useLoaderData() as Image[];
    useEffect(() => {
        try {
            setImages(gallery);
        } catch (error) {
            //console.error(error);
            if (images.length === 0) {
                setImages(initImg);
            }
        }
    }, []);
    return (
        <div className='App'>
            <Header />
            <NavBar />
            <Outlet context={images} />
            <Footer />
        </div>
    );
}

export default App;
