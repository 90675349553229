import { useState } from 'react';
import InquiryForm from '../components/InquiryForm/InquiryForm';
import { submitInquiry } from '../services/inquiryService';
import {Inquiry} from '../types';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const RequestEstimatePage = () => {

    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const [ name, setName ] = useState<string>("");

    const handleSubmit = async (formValues: Inquiry) => {
        try {
            const response = await submitInquiry(formValues);
            if (response.status == 200) {
                console.log("success");
                setName(formValues.firstName);
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error({ error });
            }
        }
        setIsOpen(true);
    };

    return (
        <div className="outlet">
            <h2 className='mainTagLineA'>
                Let&apos;s bring your vision to life
            </h2>
            <InquiryForm handleSubmit={handleSubmit} />
            <ConfirmationModal isOpen={isOpen} name={name} />
        </div>
    );
};

export default RequestEstimatePage;
