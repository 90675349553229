import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import NaturePeopleSharpIcon from '@mui/icons-material/NaturePeopleSharp';
import GiteIcon from '@mui/icons-material/Gite';
import StoreIcon from '@mui/icons-material/Store';
import ChurchIcon from '@mui/icons-material/Church';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import {List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
const FeatList = () => {
    return (
        <div className='featureRow'>
            <div className='featureTextContainer'>
                <h3>
                    <span className='mainTagLineA'>We work with a wide variety</span>
                    <br/>
                <span className='mainTagLineA'>of designs for any audience</span>
                </h3>
            </div>
            <List className='client-list'>
                <ListItem>
                    <ListItemIcon>
                        <NaturePeopleSharpIcon
                            fontSize='large'
                            color='primary'
                        />
                    </ListItemIcon>
                    <ListItemText primary='Community Spaces'>
                        Community Spaces
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <SchoolSharpIcon fontSize='large' color='primary' />
                    </ListItemIcon>
                    <ListItemText>Schools</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <StoreIcon fontSize='large' color='primary' />
                    </ListItemIcon>
                    <ListItemText>Businesses</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <ChurchIcon fontSize='large' color='primary' />
                    </ListItemIcon>
                    <ListItemText>Churches</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <GiteIcon fontSize='large' color='primary' />
                    </ListItemIcon>
                    <ListItemText>Residential</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <EmojiTransportationIcon
                            fontSize='large'
                            color='primary'
                        />
                    </ListItemIcon>
                    <ListItemText>Public Works</ListItemText>
                </ListItem>
            </List>
        </div>
    );
};

export default FeatList;
