import * as yup from 'yup';
export const inquirySchema = yup.object({
    firstName: yup.string().trim().required('Missing First Name'),
    lastName: yup.string().required('Missing Last Name'),
    organization: yup.string().ensure(),
    street: yup.string().required('Missing Address'),
    city: yup.string().required('Missing City'),
    state: yup.string().required('Missing State'),
    email: yup.string().trim().email().required('Missing Email'),
    phone: yup
        .string()
        .required('Missing Phone Number')
        .matches(
            /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}/,
            'Invalid phone number'
        ),
    preferredContact: yup
        .string()
        .oneOf(['Phone', 'Email'] as const)
        .required('Missing Preferred Contact')
        .defined(),
    wallWidth: yup
        .number()
        .positive('Enter number of inches')
        .required('Missing Mural Width'),
    wallHeight: yup
        .number()
        .positive('Enter number of inches')
        .required('Missing Mural Height'),
    muralDescription: yup.string().required('Missing Mural Description'),
    surfaceType: yup.string().notRequired().ensure(),
    surfaceColor: yup.string().notRequired().ensure()
});
