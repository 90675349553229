import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AboutPage from './AboutPage/AboutPage';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import DesignsPage from './DesignsPage/DesignsPage';
import RequestEstimatePage from './RequestEstimatePage/RequestEstimatePage';
import { getGallery } from './services/inquiryService';
const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        loader: async () => {
            return await getGallery();
        },
        children: [
            {
                path: '/',
                element: <AboutPage />
            },
            {
                path: '/designs',
                element: <DesignsPage />
            },
            {
                path: '/estimate',
                element: <RequestEstimatePage />
            }
        ]
    }
]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
