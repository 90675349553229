import { Slide } from '@mui/material';
import FeatList from '../components/FeatList/FeatList';
import ServiceList from '../components/ServiceList/ServiceList';
import DesignsPage from '../DesignsPage/DesignsPage';

const AboutPage = () => {
    return (
        <div aria-label='Plural Mural main page' id='main-page'>
            <div className='outlet'>
                <div className='featureRow'>
                    <div className='featureTextContainer'>
                        <h3 className='mainTagLineA'>
                            <span>Murals are paintings</span>
                            <br />
                            <span>meant to be shared.</span>
                        </h3>
                    </div>
                    <Slide in={true} direction='right'>
                        <div className='featureImageContainer'>
                            <img
                                className='featureImage'
                                height={512}
                                width={512}
                                src='https://firebasestorage.googleapis.com/v0/b/pluralmurals-6ac41.appspot.com/o/img%2Fsaguaros-contrast-heat-of-day-with-coolness-of-night.webp?alt=media'
                                alt='Colorful mural of several saguaros and other cacti'
                            />
                        </div>
                    </Slide>
                </div>

                <div className='featureRow'>
                    <div className='featureTextContainer'>
                        <h3 className='mainTagLineA'>
                            <span className='mainTagLineA'>A painting</span>
                            <br />
                            <span className='mainTagLineA accentText'>
                                forever-for
                            </span>
                            <br />
                            <span className='mainTagLineA'>
                                a plural audience
                            </span>
                        </h3>
                    </div>
                    <Slide in={true} direction='left'>
                        <div className='featureImageContainer'>
                            <img
                                className='featureImage'
                                src='https://firebasestorage.googleapis.com/v0/b/pluralmurals-6ac41.appspot.com/o/img%2Freptile-store-mural.webp?alt=media'
                                alt='mural of snake for reptile pet store'
                                height={512}
                                width={512}
                            />
                        </div>
                    </Slide>
                </div>
                <div className='featureRow'>
                    <div className='featureTextContainer'>
                        <h3 className='mainTagLineA'>
                            <span>
                                Plural Murals provides affordable craftsmanship
                            </span>
                            <br />
                            <span>from custom design to execution</span>
                        </h3>
                    </div>
                    <div className='featureImageContainer'>
                        <img
                            className='featureImage'
                            alt='cowboy riding bucking bull'
                            height={512}
                            width={512}
                            src='https://firebasestorage.googleapis.com/v0/b/pluralmurals-6ac41.appspot.com/o/img%2Fliteral-cow-man.webp?alt=media'


                        />
                    </div>
                </div>
                <section
                    id='we-serve-a-variety-of-spaces'
                    aria-label='we-serve-a-variety-of-spaces'>
                    <FeatList />
                </section>
                <section
                    id='interiorexterior'
                    aria-label='"We create interior and exterior murals"'>
                    <ServiceList />
                </section>
            </div>
            <div>
                <DesignsPage />
            </div>
        </div>
    );
};

export default AboutPage;
