import {Field, Form, Formik} from 'formik';
import {Inquiry, PrefContact} from '../../types';
import {LongTextField, MeasurementField, TextField} from '../../RequestEstimatePage/FormField';
import {Button, FormLabel, Stack} from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { inquirySchema } from '../../utils';
interface FormProps {
    handleSubmit: (values: Inquiry) => void;
}

const InquiryForm = ({handleSubmit}: FormProps) => {
    const initialValues: Inquiry = {
        firstName: '',
        lastName: '',
        organization: '',
        street: '',
        city: '',
        state: '',
        email: '',
        phone: '',
        preferredContact: PrefContact.Phone,
        wallHeight: 24,
        wallWidth: 24,
        surfaceType: '',
        surfaceColor: '',
        muralDescription: ''
    };

    return (
        <div className='inquiryformui'>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
                validationSchema={inquirySchema}
            >
                {({submitForm}) => (
                    <Form>
                        <Stack direction='column' alignItems='center'>
                            <Stack
                                className='formGrid'
                                direction='row'
                                spacing={1}>
                                <Field
                                    shrink
                                    label='First Name'
                                    placeHolder='First Name'
                                    name='firstName'
                                    component={TextField}
                                />
                                <Field
                                    shrink
                                    label='Last Name'
                                    placeHolder='Last Name'
                                    name='lastName'
                                    component={TextField}
                                />
                            </Stack>
                            <Stack direction="row">

                            <Field
                                
                                label='Organization'
                                placeHolder='Oranization(optional)'
                                name='organization'
                                component={TextField}
                            />
                                </Stack>
                            <Field
                                label='Street'
                                placeHolder='Street'
                                name='street'
                                component={TextField}
                            />
                            <Stack
                                className='formGrid'
                                direction='row'
                                spacing={1}>
                                <Field
                                    label='City'
                                    placeHolder='City'
                                    name='city'
                                    component={TextField}
                                />
                                <Field
                                    label='State'
                                    placeHolder='State'
                                    name='state'
                                    component={TextField}
                                />
                            </Stack>
                            <Field
                                label='Email'
                                placeHolder='Email'
                                name='email'
                                component={TextField}
                            />
                            <Field
                                label='Phone Contact'
                                placeHolder='Phone'
                                name='phone'
                                component={TextField}
                            />
                            <Stack direction="row" spacing={2} mb={2}>
                                <FormLabel id="preferred-contact-label">Preferred Method of Contact: </FormLabel>
                                <FormLabel id='prefer-email-label'>
                                <Field
                                    type="radio"
                                    label="Email"
                                    name="preferredContact"
                                    value="Email"
                                    />
                                    Email
                                </FormLabel> 
                                <FormLabel id='prefer-phone-label'>
                                <Field
                                    type="radio"
                                    label="Phone"
                                    name="preferredContact"
                                    value="Phone"
                                    />        
                                    Phone
                                    </FormLabel>
                                    </Stack>
                            <Stack
                                className='formGrid'
                                direction='row'
                                spacing={2}>
                                <Field
                                    label='Estimated Height'
                                    placeHolder='Estimated Height'
                                    name='wallHeight'
                                    component={MeasurementField}
                                />
                                <Field
                                    label='Estimated Width'
                                    placeHolder='Estimated Width'
                                    name='wallWidth'
                                    component={MeasurementField}
                                />
                            </Stack>
                            <Stack
                                className='formGrid'
                                direction='row'
                                spacing={2}>
                                <Field
                                    label='Surface Type(if known)'
                                    placeHolder='Surface Material(if known)'
                                    name='surfaceType'
                                    component={TextField}
                                />
                                <Field
                                    label='Surface Color'
                                    placeHolder='Surface Color'
                                    name='SurfaceColor'
                                    component={TextField}
                                />
                            </Stack>
                        </Stack>
                        <Field
                            label='Describe your mural'
                            placeHolder='Describe your mural'
                            name='muralDescription'
                            component={LongTextField}
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            color='secondary'
                            fullWidth={true}
                            size='large'
                            onClick={submitForm}>
                            Submit
                            <ForwardToInboxIcon />
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
export default InquiryForm;
