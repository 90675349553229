import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import LogoIcon from '../LogoIcon/LogoIcon';

const NavBar = () => {
    const navigate = useNavigate();
    const handleNavigate = (e: React.MouseEvent<HTMLElement>) => {
        const page = e.currentTarget.id.split(' ');
        navigate(`/${page[0]}`);
    };
    return (
        <AppBar position='static'>
            <Container maxWidth='xl'>
                <Toolbar disableGutters>
                    <Button
                        className='nav-btn'
                        aria-label='Navigate to home page button'
                        size='large'
                        onClick={() => navigate('/')}
                        sx={{my: 1, minWidth: 150, paddingRight: 4}}>
                        <LogoIcon />
                    </Button>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: {
                                xs: 'flex',
                                md: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'space-between'
                            }
                        }}>
                        <Button
                            key='designs'
                            onClick={handleNavigate}
                            id='designs'
                            className='nav-btn'
                            sx={{
                                my: 1,
                                color: 'white',
                                display: 'block',
                                paddingRight: '1rem'
                            }}>
                            designs
                        </Button>
                        <Button
                            key='free-estimate'
                            onClick={handleNavigate}
                            id='estimate'
                            className='nav-btn'
                            sx={{my: 1, color: 'white', display: 'block'}}>
                            free estimate
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default NavBar;
