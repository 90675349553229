export interface Inquiry {
    firstName: string,
    lastName: string,
    organization?: string | BaseOrganization,
    street: string,
    city: string,
    state: string,
    email: string,
    phone: string,
    preferredContact: PrefContact,
    wallHeight: number,
    wallWidth: number,
    surfaceType?: string,
    surfaceColor?: string,
    muralDescription: string,
}

export enum PrefContact {
    Phone = "Phone",
    Email = "Email"
}
export interface InquiryResponse {
    message: string;
}

export interface BaseOrganization {
    name: string, 
    type: string,
    id: string,
    mailingAddress: Address,
}

export interface Image {
    id: string,
    src: string,
    description: string,
    date: string | Date,
    available: boolean
}

export interface Address {
    street: string,
    city: string,
    unit: string,
    state: string,
}